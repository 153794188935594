<template>
  <dialogForm
    :title="dialogOption.title"
    :btnText="dialogOption.text"
    :btnType="dialogOption.type"
    width="600"
    customClass="import-product-template"
    v-model="form"
    :option="option"
    @before-open="beforeOpen"
    @closed="form = {}"
    @submit="onsubmit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #template="{ form, prop }">
      <uploadTemplate v-model="form[prop]" :fileName.sync="form.templateName" :file.sync="form.file" />
    </template>
  </dialogForm>
</template>

<script>
import uploadTemplate from '@/views/productTemplate/templateManage/module/uploadTemplate'
import templateApi from '@/api/productTemplate/index'
import { cloneDeep } from 'lodash'

export default {
  components: { uploadTemplate },
  props: {
    type: {
      type: String,
      default: 'default'
    },
    data: {
      type: Object,
      default: () => ({})
    },
    categoryId: Number | String,
    shopTypeId: Number | String
  },
  data() {
    return {
      form: {},
      option: {
        labelWidth: 112,
        span: 20,
        column: [
          {
            label: '系统模板名称',
            prop: 'templateName',
            rules: [{ required: true }]
          },
          /*{
            label: '是否覆盖',
            prop: 'isCover',
            rules: [{ required: true }],
            type: 'radio',
            dicData: [
              {
                label: '是',
                value: 1
              },
              {
                label: '否',
                value: 0
              }
            ]
          },*/
          {
            label: '上传模板',
            prop: 'template',
            rules: [{ required: true }]
          }
        ]
      }
    }
  },
  computed: {
    dialogOption({ type }) {
      return {
        default: {
          title: '导入模板',
          text: '导入系统模板',
          type: 'primary'
        },
        
        reUpload: {
          title: '重新导入模板',
          text: '重新上传',
          type: 'text'
        }
      }[type]
    }
  },
  methods: {
    async onsubmit(form, done) {
      const res = await awaitResolve(templateApi.importTemplate({
        isCover: form.isCover,
        categoryId: this.categoryId,
        shopTypeId: this.shopTypeId,
        productType: form.template.productType,
        dataDefinitionDTOList: form.template.fields.map((field) => ({
          fieldName: field['Field Name'],
          columnGroupType: field.columnGroupType,
          isRequired: field['Required?'] === 'Required' ? 1 : 0
        })),
        productTemplateDTO: {
          id: this.data.id,
          templateName: form.templateName
        },
        validValues: form.template.mainArray.map(item => {
          const tmpObj = {}
          for (const itemKey in item) {
            tmpObj.fieldName = itemKey
            tmpObj.values = item[itemKey]
          }
          return tmpObj
        })
      }))
      if (!res) return done(false)

      const formData = new FormData()
      formData.append('file', form.file)
      formData.append('id', res.detail)
      if (!await done(templateApi.uploadTemplate(formData))) return

      // this.$store.commit('SET_BUS', { addTemplate: form })
      this.$router.push({
        path: '/productTemplate/addTemplate', 
        query: { id: res.detail, shopTypeId: this.shopTypeId }
      })
    },

    beforeOpen() {
      this.form = cloneDeep(this.data)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
